import { Locale } from "date-fns";
import { enUS, es } from "date-fns/locale";

const checkBrowserLanguage = (): Locale => {
  if (navigator.language.includes("es")) {
    return es;
  }

  return enUS;
};

export { checkBrowserLanguage };
