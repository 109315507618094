import { BadWordsType } from "../components/EventCreation/EventAttachments/EventAttachments";
import {
  EventSetUpConfiguration,
  FiraEventForm,
  FiraParticipant,
  ResponseValidModerator,
  AddEventProductForm,
  StartEventResponse,
  streamBlock,
  SendPopUpForm,
  ResponseSimpleProduct,
  FiraConfiguration,
  ResponseLiveData,
  FiraUserModerator,
  ResponseSummary,
  UserInfoResponse,
  EventGeneralDetailsType,
  DashboardEvent,
  NextEventList,
  DashboardCountingResponse,
  FiraMultiEventForm,
  ResponseCurrentPopup,
} from "../utils/types";
import {
  apiActionsPrivate,
  apiBusiness,
  apiManagement,
  apiManagementPrivate,
  apiServicePrivate,
  apiBroadcastingService,
  apiBusinessPrivate,
} from "./api";
/*import { QueueResponse } from "./socket-api";*/
import { EventType, StreamProvider } from "../utils/enums";
import { EventResumeResponse } from "../types/Response";

export const getStoreEventsService = (storeId: string) => {
  return apiServicePrivate.get(
    `/v1/live-broadcast-event/find/all-by-store/${storeId}`
  );
};

export const postCreateEventsService = (
  form: FiraEventForm,
  eventType: EventType = EventType.broadcast
) => {
  return apiServicePrivate.post(
    `/v1/live-broadcast-event/${eventType}/create`,
    form
  );
};

export const putCancelEventService = (eventId: string) => {
  return apiServicePrivate.put(
    `/v1/live-broadcast-event/stage/on-cancelled/${eventId}`
  );
};

export const getFiraWebConfigurationService = (storeId: string) => {
  return apiManagementPrivate.get(
    `/api/v1/fira-web-configuration/by-store-id/${storeId}`
  );
};

export const getStreamBlocksService = () => {
  return apiManagementPrivate.get(`/api/v1/get-stream-blocks/default`);
};

export const createStreamBlocksService = (body: streamBlock) => {
  return apiManagementPrivate.post(`/api/v1/get-stream-blocks/create`, body);
};

export const postFiraWebConfigurationService = (
  body: EventSetUpConfiguration
) => {
  return apiServicePrivate.post(
    "/v1/live-broadcast-event/first-setting-up",
    body
  );
};

export const getEventService = (eventId: string) => {
  return apiManagementPrivate.get(
    `/api/v1/fira-broadcast-event-controller/${eventId}`
  );
};

export const generateCredentialsService = (
  eventId: string,
  provider?: string
) => {
  let credentialsProv = "";
  if (provider) {
    credentialsProv = "?provider=" + provider;
  }
  return apiManagementPrivate.get(
    `/api/v1/fira-broadcast-event-controller/generate-credentials/${eventId}${
      provider ? credentialsProv : ""
    }`
  );
};

export const updateCredentialsService = (eventId: string) => {
  return apiManagementPrivate.get<ResponseLiveData>(
    `/api/v1/fira-broadcast-event-controller/live-data/${eventId}`
  );
};

export const getPrevWebConfigurationsService = (
  storeId: string,
  offSet: number,
  limit: number
) => {
  return apiServicePrivate.get(
    `/v1/live-broadcast-event/configuration/retrieve-all/${storeId}/${offSet}/${limit}`
  );
};

// BAD WORDS

export const getDefaultBadWordsService = (
  storeId: string,
  type: BadWordsType
) => {
  return apiManagementPrivate.get(`/api/v1/bad-words/${storeId}/type/${type}`);
};

export const putCustomBadWordsService = (storeId: string, body: string[]) => {
  return apiManagementPrivate.put(
    `/api/v1/bad-words/bad-words/${storeId}`,
    body
  );
};

// --end of BAD WORDS

export const startBroadcastService = (eventId: string) => {
  return apiManagementPrivate.post<StartEventResponse>(
    `/api/v1/fira-broadcast-event-controller/start-broadcast/${eventId}`
  );
};

export const finishBroadcastService = (eventId: string) => {
  return apiManagementPrivate.put(
    `/api/v1/fira-broadcast-event-controller/finish-broadcast/${eventId}`
  );
};

export const getEventProducts = (eventId: string) => {
  return apiServicePrivate.get(
    `/v1/live-broadcast-event/broadcast-product/retrieve-all/${eventId}`
  );
};

export const getActiveEventProducts = (eventId: string) => {
  return apiServicePrivate.get(
    `/v1/live-broadcast-event/broadcast-product/retrieve-all-activates/${eventId}`
  );
};

export const getInactiveEventProducts = (eventId: string) => {
  return apiServicePrivate.get<ResponseSimpleProduct[]>(
    `/v1/live-broadcast-event/broadcast-product/retrieve-all-deactivates/${eventId}`
  );
};

export const getEventPopups = (
  eventId: string
): Promise<ResponseCurrentPopup[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiActionsPrivate.get<ResponseCurrentPopup[]>(
        `/v1/live-broadcasting-actions/popup-remaining/${eventId}`
      );

      resolve(response.data);
    } catch (error) {
      console.error("ERROR retriving popups", error);
      reject([]);
    }
  });
};

export const validateModerator = (eventId: string) => {
  return apiManagementPrivate.get<ResponseValidModerator>(
    `/api/v1/fira-broadcast-event-controller/chat-moderator/${eventId}`
  );
};

export const addEventProductsOnStreaming = (body: AddEventProductForm) => {
  return apiServicePrivate.post(
    `/v1/live-broadcast-event/broadcast-product/add-relationships-on-streaming`,
    body
  );
};

export const reactiveEventProductsOnStreaming = (
  eventId: string,
  productId: string
) => {
  return apiServicePrivate.put(
    `/v1/live-broadcast-event/broadcast-product/reactivate-on-streaming/${eventId}/${productId}`
  );
};

export const sendPopUp = (body: SendPopUpForm) => {
  return apiServicePrivate.post(
    `/v1/live-broadcast-event/broadcast-product/popup-on-streaming/activate`,
    body
  );
};

export const deletePopup = (eventId: string, productId: string) => {
  return apiServicePrivate.put(
    `/v1/live-broadcast-event/broadcast-product/popup-on-streaming/deactivate/${eventId}/${productId}`
  );
};

export const editFiraWebConfigurationService = (body: {
  id: string;
  firaWebConfiguration: FiraConfiguration;
}) => {
  return apiServicePrivate.post(
    "/v1/live-broadcast-event/configuration/edit",
    body
  );
};

//DASHBOARD
export const dashboardBroadcastCounting = (
  storeId: string,
  firstDate: string,
  secondDate: string
) => {
  return apiServicePrivate.get<DashboardCountingResponse>(
    `/v1/live-broadcast-event/dashboard/broadcasting-counting/all-stats/${storeId}/${firstDate}/${secondDate}`
  );
};

export const dashboardBroadcastNextEvents = (
  storeId: string,
  currentDate: string,
  offSet: number,
  pageSize: number
) => {
  return apiServicePrivate.get<NextEventList[]>(
    `/v1/live-broadcast-event/dashboard/broadcasting/next-events/${storeId}/${currentDate}/${offSet}/${pageSize}`
  );
};

export const dashboardBroadcastEventsList = (
  storeId: string,
  firstDate: string,
  secondDate: string,
  offSet: number,
  pageSize: number
) => {
  return apiServicePrivate.get<DashboardEvent[]>(
    `/v1/live-broadcast-event/dashboard/broadcasting-counting/all-event/${storeId}/${firstDate}/${secondDate}/${offSet}/${pageSize}`
  );
};
export const getEventParticipants = (eventId: string) => {
  return apiManagementPrivate.get<FiraParticipant[]>(
    `/api/v1/fira-broadcast-event-controller/list-chat-user/${eventId}`
  );
};

/*export const getEventQueues = (eventId: string) => {
  return apiServicePrivate.get<QueueResponse>(
    `/v1/live-broadcast-event/find/current-queues/${eventId}`
  );
};*/

export const getLiveSumary = (eventId: string) => {
  return apiActionsPrivate.get<ResponseSummary>(
    `/v1/live-broadcasting-actions/cms/current-summary/${eventId}`
  );
};

export const getUsersSumary = (eventId: string) => {
  return apiManagementPrivate.get<UserInfoResponse[]>(
    `/api/v1/fira-chat-user-data-controller/all/${eventId}`
  );
};

//this services are only for the ended event

export const getMobileConnections = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/mobile-conn/$${eventId}`
  );
};

export const getWebConnections = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/web-conn/${eventId}`
  );
};

export const getLikes = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/like/${eventId}`
  );
};

export const getShares = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/share/${eventId}`
  );
};

export const getRegularClicks = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/regular-click/${eventId}`
  );
};

export const getPopupClicks = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/popup-click/${eventId}`
  );
};

export const getShoppingCart = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/shopping-cart/${eventId}`
  );
};

export const getBroadcastingProduct = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/get-all-broadcasting-product/${eventId}`
  );
};

export const getEventCategories = () => {
  return apiManagementPrivate.get("/api/v1/fira-event-category-controller/all");
};

export const getStoresBusinessClient = () => {
  return apiManagementPrivate.get("/api/v1/fira-store/business-client");
};
/** DEPRECATED **/
export const editEventGeneralDetails = (
  data: EventGeneralDetailsType,
  teaser?: File, //REMOVE THIS
  preview?: File //REMOVE THIS
) => {
  const body = new FormData();
  const date = new Date(data.scheduleDate);
  const d = date.toISOString();
  body.append("broadcastingId", data.broadcastingId);
  body.append("eventName", data.eventName);
  body.append("scheduleDate", d);
  if (data.eventDescription !== undefined) {
    body.append("eventDescription", data.eventDescription);
  } else {
    body.append("eventDescription", "");
  }
  if (data.firaEventCategory !== undefined) {
    data.firaEventCategory.map((cat) => {
      body.append("categories", cat.description);
    });
  }
  if (data.imageAvailable) {
    if (preview) body.append("image", preview, preview?.name);
  } else body.append("image", new Blob(undefined, { type: "image/png" }), "");

  if (data.teaserAvailable) {
    if (teaser) body.append("teaser", teaser, teaser?.name);
  } else body.append("teaser", new Blob(undefined, { type: "video/mp4" }), "");

  const headers = {
    "Content-Type": `multipart/form-data`,
  };
  return apiServicePrivate.post("/v1/live-broadcast-event/edit", body, {
    headers,
  });
};
/** DEPRECATED **/

export const getDataMinuteByMinute = (eventId: string, action: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/minute-by-minute/${action}/${eventId}`
  );
};

export const getProductDataMinuteByMinute = (
  eventId: string,
  action: string
) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/minute-by-minute/product/${action}/${eventId}`
  );
};

export const getChatDataMinuteByMinute = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/minute-by-minute/chat/${eventId}`
  );
};

export const getViewsDataMinuteByMinute = (eventId: string) => {
  return apiActionsPrivate.get(
    `/v1/live-broadcasting-actions/post-live-event-actions/minute-by-minute/connections/${eventId}`
  );
};

//ACCOUNT OWNER services

export const dashboardOwnerCurrentStats = (businessClientId: string) => {
  return apiManagementPrivate.get(`/v1/fira-business/owner-dashboard/event-statistics-current/${businessClientId}
  `);
};

export const dashboardOwnerStatsByDate = (
  businessClientId: string,
  initialDate: string,
  finalDate: string
) => {
  return apiBusinessPrivate.get(`/v1/fira-business/owner-dashboard/event-statistics-daily-by-date/${businessClientId}/${initialDate}/${finalDate}
  `);
};

export const dashboardOwnerAllStats = (subscription: string) => {
  return apiBusinessPrivate.get(
    `/v1/fira-business/subscriptions/retrieve-all-stats/${subscription}`
  );
};

export const createMultipleEventsService = (
  form: FiraMultiEventForm,
  eventType: EventType = EventType.broadcast
) => {
  return apiServicePrivate.post(
    `/v1/live-broadcast-event/multi/${eventType}/create`,
    form
  );
};
export const createMassiveEventsService = (
  form: FiraMultiEventForm,
  eventType: EventType = EventType.broadcast
) => {
  return apiServicePrivate.post(
    `/v1/live-broadcast-event/massive/${eventType}/create`,
    form
  );
};
export const getAllBusinessEventsByDate = (
  businessClientId: string,
  initDateTime: string,
  endDateTime: string
) => {
  return apiServicePrivate.get(
    `/v1/live-broadcast-event/find/all-by-bc/${businessClientId}/${initDateTime}/${endDateTime}`
  );
};

export const getAllByssinessByName = (
  businessClientId: string,
  name: string
) => {
  return apiServicePrivate.get(
    `/v1/live-broadcast-event/find/all-by-bc-name/${businessClientId}?eventName=${name}`
  );
};
export const getEventResume = (broadcastingId: string) => {
  return apiServicePrivate.get<EventResumeResponse>(
    `/v1/live-broadcast-event/get-details-resume/${broadcastingId}`
  );
};

/** NEW EVENT WIZARD SERVICES **/
export const editEventGeneralDetailsService = (body: FormData) => {
  const headers = {
    "Content-Type": `multipart/form-data`,
  };
  return apiServicePrivate.post("/v1/live-broadcast-event/edit", body, {
    headers,
  });
};

export const addEventProducts = (body: AddEventProductForm) => {
  return apiServicePrivate.post(
    `/v1/live-broadcast-event/broadcast-product/add`,
    body
  );
};

export const deleteEventProduct = (eventId: string, productId: string) => {
  return apiServicePrivate.put(
    `/v1/live-broadcast-event/broadcast-product/delete/${eventId}/${productId}`
  );
};

export const deleteModeratorService = (body: {
  broadcastingId: string;
  idList: string[];
}) => {
  return apiServicePrivate.post(
    `/v1/live-broadcast-event/moderator/delete`,
    body
  );
};

export const addModeratorService = (body: {
  broadcastingId: string;
  moderatorList: FiraUserModerator[];
}) => {
  return apiServicePrivate.post(`/v1/live-broadcast-event/moderator/add`, body);
};

export const getChatUserDataService = (connectionId: string) => {
  return apiManagementPrivate.get(
    `/api/v1/fira-chat-user-data-controller/user-data/${connectionId}`
  );
};
