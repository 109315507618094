import axios from "axios";
import { FiraLoginResponse, SignUpExpressResponse } from "../types/Response";
import { apiBusiness, apiBusinessPrivate } from "./api";
import { SalesforceUserInfo } from "../utils/types";

export const loginService = (
  password: string,
  username?: string,
  email?: string,
  phone?: string
) => {
  if (email) {
    return apiBusiness.put<FiraLoginResponse>(
      "/v1/authentication-service/login",
      {
        email,
        password,
      }
    );
  } else if (phone) {
    return apiBusiness.put<FiraLoginResponse>(
      "/v1/authentication-service/login",
      {
        phone,
        password,
      }
    );
  }

  return apiBusiness.put<FiraLoginResponse>(
    "/v1/authentication-service/login",
    {
      username,
      password,
    }
  );
};

export const logoutService = (sessionId: string) => {
  return apiBusinessPrivate.put(
    `/v1/authentication-service/logout/${sessionId}`
  );
};

export const refreshTokenService = (sessionId: string, storeId?: string) => {
  if (storeId) {
    return apiBusiness.put<FiraLoginResponse>(
      `/v1/authentication-service/refresh-token/${sessionId}/${storeId}`
    );
  }

  return apiBusiness.put<FiraLoginResponse>(
    `/v1/authentication-service/account-owner/refresh-token/${sessionId}`
  );
};

export const signUpExpress = (data: any): Promise<SignUpExpressResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiBusiness.post<SignUpExpressResponse>(
        "/v1/user-service/create-custom-user",
        data
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSalesforceUserInfo = (
  code: string,
  verifier: string,
  country: string = "mx",
  businessclientId: string
) => {
  return new Promise<{
    token: string;
    uri: string;
    email: string;
    firstName: string;
    lastName: string;
    username: string;
  }>(async (resolve, reject) => {
    try {
      const response = await apiBusiness.post<{
        token: string;
        uri: string;
        email: string;
        firstName: string;
        lastName: string;
        username: string;
      }>("/v1/super-admin/token-salesforce", {
        code,
        verifier,
        country,
        businessclientId,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
