import { useTranslation } from "react-i18next";
import { Table, TableHead, TableRow } from "../../components";
import { DataActionProductsStats, ProductClicksTable } from "../../utils/types";
import styles from "./ProductListWidget.module.scss";
import noImageStoreSVG from "../../assets/svg/no-image-store.svg";
import { TableCellNoBorder, TableStyles } from "../../components/Table/Table";
import Skeleton from "../../components/Skeleton/Skeleton";
import { useEffect, useState } from "react";
import EmptyProductsWidget from "../../components/FiraIcons/EmptyProductsWidget";

interface Props {
  title: string;
  subtitle?: string;
  products: ProductClicksTable[] | DataActionProductsStats[];
  height?: string;
  loading?: boolean;
  extraColumn?: boolean;
  isStatsProducts: boolean;
  header?: string[];
  type?: "INTENTION" | "REGULAR";
}

const ProductListWidget = ({
  title,
  subtitle,
  products,
  height,
  loading,
  extraColumn,
  isStatsProducts,
  header,
  type,
}: Props) => {
  const { t } = useTranslation();
  const [dataIsEmpty, setDataIsEmpty] = useState<boolean>(false);
  const [clicksHeaderData, setClicksHeaderData] = useState<
    { name: string; size: string }[]
  >([]);

  useEffect(() => {
    if (extraColumn) {
      clicksHeaderData.push({ name: "Tienda", size: "" });
    } else {
      clicksHeaderData.push({ name: "", size: "20px" });
    }
  }, [products]);

  useEffect(() => {
    if (header) {
      let headerObj = header.map((h, i) => {
        return {
          name: h,
          size: "",
        };
      });
      headerObj = [
        { name: "", size: "40px" },
        {
          name: t("views.events.eventDetail.products_clicks.product"),
          size: "",
        },
        ...headerObj,
      ];

      setClicksHeaderData(headerObj);
    }
  }, []);

  const clickHeader = header ? (
    <TableRow>
      {clicksHeaderData.map((head, i) => (
        <TableHead
          paddingLeft={i == 0}
          background
          key={i}
          size={head.size}
          centerContent={i > 1}
        >
          <span className={styles.titleFormat}>{head.name}</span>
        </TableHead>
      ))}
    </TableRow>
  ) : (
    <TableRow>
      <TableHead background size={"40px"}>
        <span className={styles.titleFormat}></span>
      </TableHead>
      <TableHead background size={""}>
        <span className={styles.titleFormat}>
          {t("views.events.eventDetail.products_clicks.product")}
        </span>
      </TableHead>
      <TableHead background size={""}>
        <span className={styles.titleFormat}>
          {t("views.events.eventDetail.products_clicks.clicks")}
        </span>
      </TableHead>
      <TableHead background size={"20px"}></TableHead>
    </TableRow>
  );

  const clicksBody = (products as ProductClicksTable[]).map(
    (product, index) => {
      if (product.summary) {
        return (
          <TableRow key={index} shrink>
            <TableCellNoBorder size="50px" noPadding shrink>
              <div className={styles.tableImg}>
                <img
                  src={
                    product.info.images.length !== 0 &&
                    product.info.images[0] !== ""
                      ? product.info.images[0]
                      : noImageStoreSVG
                  }
                />
              </div>
            </TableCellNoBorder>
            <TableCellNoBorder noPadding shrink>
              <div className={styles.nameRow}>
                <span className={styles.tableEllipsis}>
                  {product.info.name}
                </span>
              </div>
            </TableCellNoBorder>
            {/* total de click normales */}
            {!header ? (
              <>
                <TableCellNoBorder centerContent shrink>
                  <span>
                    {product.summary.rcPriceOneCounting +
                      product.summary.rcPriceTwoCounting +
                      product.summary.rcPriceThreeCounting +
                      product.summary.pcPriceOneCounting +
                      product.summary.pcPriceTwoCounting +
                      product.summary.pcPriceThreeCounting}
                  </span>
                </TableCellNoBorder>
              </>
            ) : type === "REGULAR" ? (
              <>
                <TableCellNoBorder>
                  {product.summary.rcPriceOneCounting}
                </TableCellNoBorder>
                <TableCellNoBorder>
                  {product.summary.pcPriceOneCounting}
                </TableCellNoBorder>
                <TableCellNoBorder>
                  {product.summary.rcPriceOneCounting +
                    product.summary.pcPriceOneCounting}
                </TableCellNoBorder>
              </>
            ) : (
              <>
                <TableCellNoBorder>
                  {product.summary.rciPriceOneCounting}
                </TableCellNoBorder>
                <TableCellNoBorder>
                  {product.summary.pciPriceOneCounting}
                </TableCellNoBorder>
                <TableCellNoBorder>
                  {product.summary.rciPriceOneCounting +
                    product.summary.pciPriceOneCounting}
                </TableCellNoBorder>
              </>
            )}

            <TableCellNoBorder shrink centerContent></TableCellNoBorder>
          </TableRow>
        );
      }
    }
  );

  const clicksStatsBody = (products as DataActionProductsStats[]).map(
    (product, index) => {
      if (product.count >= 0) {
        return (
          product.info && (
            <TableRow key={index} shrink>
              <TableCellNoBorder noPadding shrink>
                <div className={styles.nameRow}>
                  <div className={styles.tableImg}>
                    <img
                      src={
                        product.info.image.length != 0
                          ? product.info.image[0]
                          : noImageStoreSVG
                      }
                    />
                  </div>
                </div>
              </TableCellNoBorder>
              <TableCellNoBorder noPadding shrink>
                <span className={styles.tableEllipsis}>
                  {product.info.productName}
                </span>
              </TableCellNoBorder>
              {/* total de click normales */}
              <TableCellNoBorder centerContent shrink>
                <span>{product.count}</span>
              </TableCellNoBorder>
              <TableCellNoBorder
                size="10px"
                shrink
                centerContent
              ></TableCellNoBorder>
            </TableRow>
          )
        );
      }
    }
  );

  useEffect(() => {
    if (products.length !== 0) {
      setDataIsEmpty(false);
    } else {
      setDataIsEmpty(true);
    }
  }, [products]);

  return (
    <div className={styles.productWidgetContainer} style={{ height }}>
      {loading ? (
        <LoadingStateProductWidget />
      ) : (
        <>
          <div className={styles.header}>
            <h6>{title}</h6>
            <p className="small-text">{subtitle}</p>
          </div>
          <div className={styles.tableContainer}>
            <Table
              tableStyle={TableStyles.noHeader}
              header={clickHeader}
              body={isStatsProducts ? clicksStatsBody : clicksBody}
              headerHeight={"31px"}
              height={dataIsEmpty ? "20%" : "100%"}
            />
            {dataIsEmpty && <EmptyStateProductWidget />}
          </div>
        </>
      )}
    </div>
  );
};

const EmptyStateProductWidget = () => {
  return (
    <div className={styles.emptyProducts}>
      <EmptyProductsWidget />
    </div>
  );
};

const LoadingStateProductWidget = () => {
  return (
    <div className={styles.LoadingState}>
      <Skeleton width="100%" height="22px" />
      <Skeleton width="50%" height="22px" />
    </div>
  );
};
export default ProductListWidget;
