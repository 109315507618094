import axios from "axios";
import { apiManagementPrivate } from "./api";

export const getCountriesService = () => {
  return apiManagementPrivate.get("/api/v1/countries/all");
};

export const getLanguageService = () => {
  return apiManagementPrivate.get("/api/v1/language/get-all");
};

export const getCurrenciesService = () => {
  return apiManagementPrivate.get("/api/v1/currencies/find-all");
};

export interface CountryCode {
  name: string;
  countryCode: string;
  dialCode: string;
  flagUrl: string;
}
export const getPhoneCodes = (): Promise<CountryCode[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const formattedData: CountryCode[] = response.data
        .map((country: any) => {
          let dialCode =
            country.idd.root +
            (country.idd.suffixes ? country.idd.suffixes[0] : "");
          if (country.cca2 === "US") {
            dialCode = "+1";
          }
          return {
            name: country.name.common,
            countryCode: country.cca2,
            dialCode,
            flagUrl: `https://flagcdn.com/w20/${country.cca2.toLowerCase()}.png`,
          };
        })
        .sort((a: CountryCode, b: CountryCode) => a.name.localeCompare(b.name));
      resolve(
        formattedData.filter((country) => country.dialCode !== "undefined")
      );
    } catch (error) {
      reject(error);
    }
  });
};
