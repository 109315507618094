import { useNavigate } from "react-router-dom";
import FiraNavBar from "../../FiraNavBar/FiraNavBar";
import Input from "../../Input/Input";
import styles from "./CreateStoreForm.module.scss";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import CustomSelect, {
  CustomSelectStyle,
} from "../../CustomSelect/CustomSelect";
import Button, { ButtonStyle } from "../../Button/Button";
import { Currencies, Languages, RootState } from "../../../utils/types.d";
import { useDispatch, useSelector } from "react-redux";
import { ownerStoreState } from "../../../utils/enums";
import { useTranslation } from "react-i18next";
import { usePopupAlert, AlertType } from "../../../providers/AlertProvider";
import { createOwnerStoreActionCreator } from "../../../store/Brand/BrandActions";
import {
  getLanguageService,
  getCurrenciesService,
} from "../../../services/utils";
import { StoreType } from "../../../types/Stores.d";

interface optionType {
  id: string;
  name: string;
  value: string;
}

interface CreateStoreForm {
  name: string;
  url: string;
  language: string;
  currency: string;
}

enum SelectType {
  language = "LANGUAGE",
  currency = "CURRENCY",
}

const initialState: CreateStoreForm = {
  name: "",
  url: "",
  language: "",
  currency: "",
};

const CreateStoreForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showAlert = usePopupAlert();

  const { authentication, brand } = useSelector((state: RootState) => state);
  const [currencies, setCurrencies] = useState<optionType[]>([]);
  const [currenciesOpt, setCurrenciesop] = useState<optionType[]>([]);
  const [languages, setLanguages] = useState<optionType[]>([]);
  const [langOptions, setLangOptions] = useState<optionType[]>([]);
  const [formState, setFormState] = useState<CreateStoreForm>(initialState);
  const [urlValid, setUrlValid] = useState(false);

  const urlRegex = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/))(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)(\.){1,}[a-zA-Z0-9()]*\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=])*/gi
  );

  //llena la lista base y la que se va a filtrar para los selects
  const initSelects = async () => {
    try {
      const languageResponse = await getLanguageService();
      const langData = optionFormat(languageResponse.data);
      setLanguages(langData);
      setFormState((prevState) => ({
        ...prevState,
        ["language"]: langData[0].name,
      }));
      const currencyResponse = await getCurrenciesService();
      const currData = optionFormat(currencyResponse.data);
      setCurrencies(currData);
      setFormState((prevState) => ({
        ...prevState,
        ["currency"]: currData[0].name,
      }));
    } catch (error) {
      console.error("error retriving selects values");
    }
  };

  //formatea las opciones recibidas por los servicios
  const optionFormat = (arr: Currencies[] | Languages[]) => {
    let tempArr: optionType[] = [];
    arr?.map((val, i) => {
      const tempObj = {
        id: String(i),
        name: val.name,
        value: val.name,
      };
      tempArr.push(tempObj);
    });

    return tempArr;
  };

  //filtra la lista a mostrar, sacando el que ya esta seleccionado
  const filterList = (optionName: string, type: SelectType) => {
    if (type === SelectType.currency) {
      const newCurrList = currencies.filter((lang) => {
        return lang.name !== optionName;
      });
      setCurrenciesop(newCurrList);
    } else {
      const newLangList = languages.filter((lang) => {
        return lang.name !== optionName;
      });
      setLangOptions(newLangList);
    }
  };

  //llena el form con los inputs
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "url") {
      const validated = urlRegex.test(value);
      setUrlValid(validated);
    }
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //llena el form con los select
  const selectOption = (selectedOp: optionType, type: SelectType) => {
    if (type === SelectType.currency) {
      setFormState((prevState) => ({
        ...prevState,
        ["currency"]: selectedOp.name,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        ["language"]: selectedOp.name,
      }));
    }
  };

  //crea el form con el formato para el servicio y lo envia
  const handleCreate = (event: FormEvent) => {
    event.preventDefault();
    if (authentication.user) {
      const form = {
        name: formState.name,
        businessClientId: authentication.user?.businessClientId
          ? authentication.user.businessClientId
          : "",
        regionalConfiguration: {
          language: formState.language,
          currency: formState.currency,
        },
        state: ownerStoreState.active,
        contact: {
          webpages: [formState.url],
        },
        storeType: StoreType.VARIOUS,
      };
      dispatch(createOwnerStoreActionCreator(form));
    }
  };

  useEffect(() => {
    initSelects();
  }, []);

  useEffect(() => {
    filterList(formState.currency, SelectType.currency);
  }, [formState.currency]);

  useEffect(() => {
    filterList(formState.language, SelectType.language);
  }, [formState.language]);

  useEffect(() => {
    if (brand.error) {
      showAlert(AlertType.error, brand.error.message);
    }
  }, [brand.error]);
  useEffect(() => {
    if (brand.createSuccess) {
      navigate(-1);
    }
  }, [brand.createSuccess]);

  return (
    <div>
      <FiraNavBar
        backButtonHandler={() => navigate(-1)}
        titleSection={t("components.createStoreForm.title")}
      />
      <div className={styles.CreateStoreFormWrapper}>
        <div className={styles.formTitle}>
          <span>{t("components.createStoreForm.formTitle")}</span>
        </div>
        <form className={styles.formWrapper}>
          <fieldset className={styles.formGroup}>
            <label htmlFor="store-name">
              {t("components.createStoreForm.name")}
            </label>
            <Input
              inputName="name"
              inputId="store-name"
              width="100%"
              height="40px"
              placeholder={t("components.createStoreForm.name")}
              value={formState.name}
              onChange={handleInput}
            />
          </fieldset>
          <fieldset className={styles.formGroup}>
            <label htmlFor="store-name">
              {t("components.createStoreForm.url")}
            </label>
            <Input
              inputName="url"
              inputId="store-name"
              width="100%"
              height="40px"
              placeholder={t("components.createStoreForm.url")}
              value={formState.url}
              onChange={handleInput}
            />
          </fieldset>

          <div className={styles.rowGroup}>
            <fieldset className={styles.formGroup}>
              <label>{t("components.createStoreForm.language")}</label>
              <CustomSelect
                options={langOptions}
                handleSelected={selectOption}
                value={formState.language}
                selectStyle={CustomSelectStyle.border}
                width={"100%"}
                height={"40px"}
                selectId={SelectType.language}
              />
            </fieldset>
            <fieldset className={styles.formGroup}>
              <label>{t("components.createStoreForm.currency")}</label>
              <CustomSelect
                options={currenciesOpt}
                handleSelected={selectOption}
                value={formState.currency}
                selectStyle={CustomSelectStyle.border}
                width={"100%"}
                height={"40px"}
                selectId={SelectType.currency}
              />
            </fieldset>
          </div>

          <div className={styles.buttonsGroup}>
            <Button width="136px" height="32px" buttonStyle={ButtonStyle.light}>
              <span>{t("components.createStoreForm.cancel")}</span>
            </Button>
            <Button
              width="136px"
              height="32px"
              isDisabled={
                !urlValid || formState.name.length === 0 || brand.isLoading
              }
              clickHandler={handleCreate}
            >
              <span>{t("components.createStoreForm.create")}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateStoreForm;
