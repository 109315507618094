import { Currency } from "./types.d";

const currencyFormat = (value: number, currency?: string) => {
  let country = "EN";
  let newCurrency = currency;
  switch (currency) {
    case Currency.ars:
      country = "AR";
      break;
    case Currency.brl:
      country = "BR";
      break;
    case Currency.clp:
      country = "es-CL";
      break;
    case Currency.cop:
      country = "CO";
      break;
    case Currency.eur:
      country = "ES";
      break;
    case Currency.mxn:
      country = "MX";
      break;
    case Currency.pen:
      country = "PE";
      break;
    case Currency.usd:
      country = "EN";
      break;
    case Currency.gtq:
      country = "es-GT";
      break;
    case Currency.nio:
      country = "es-NI";
      break;
    case Currency.crc:
      country = "es-CR";
      break;
    case Currency.hnl:
      country = "es-HN";
      break;
    case Currency.svc:
      country = "es-SV";
      newCurrency = Currency.usd;
      break;
    default:
      country = "EN";
      break;
  }

  return new Intl.NumberFormat(country, {
    style: "currency",
    currency: newCurrency,
  }).format(value);
};

export default currencyFormat;
